const {
  REACT_APP_STRIPE_PRICE_PRINTER,
  REACT_APP_STRIPE_PRICE_SUBSCRIPTION_MONTHLY,
  REACT_APP_STRIPE_PRICE_SUBSCRIPTION_ANNUAL,
} = process.env;

const printer = {
  name: "Eatabit Wi-Fi Printer",
  model: 10,
  price: 250,
  priceId: REACT_APP_STRIPE_PRICE_PRINTER,
};
const subscriptions = [
  {
    price: 10,
    term: "Monthly",
    title: "$10/mo",
    stripePriceId: REACT_APP_STRIPE_PRICE_SUBSCRIPTION_MONTHLY,
  },
  {
    price: 110,
    term: "Annual",
    title: "$110/yr",
    stripePriceId: REACT_APP_STRIPE_PRICE_SUBSCRIPTION_ANNUAL,
  },
];

export { printer, subscriptions };
