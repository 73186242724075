/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import LanguageIcon from "@mui/icons-material/Language";

const mock = [
  {
    title: "Designed to be simple",
    subtitle:
      "From standardized APIs to 2-way communicating printers that require zero training, we like to keep it simple.",
    icon: <CloudDoneIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    title: "Maximum efficiency",
    subtitle:
      "Our printers work with any retail workflow, giving you and your retail partners a universal order transmission solution.",
    icon: <PedalBikeIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    title: "Always-on reliablity",
    subtitle:
      "Our cloud infrastructure and Wi-Fi printers have delivered over 10 million orders to international retailers of all types.",
    icon: <LanguageIcon sx={{ fontSize: "2rem" }} />,
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={"center"}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            Open up a direct line to your retailers
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            align={"center"}
          >
            Build your business on the simplest, most efficient and most
            reliable printing network.
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={1}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={"center"}
                >
                  {item.title}
                </Typography>
                <Typography align={"center"} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
