import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

import { company } from "constants/company";

const ContactCard = () => {
  const theme = useTheme();

  const handleOnPrint = (event) => {
    event.preventDefault();
    window.print();
  };

  return (
    <Box
      component={Card}
      boxShadow={0}
      border={`1px solid ${theme.palette.divider}`}
    >
      <Box padding={{ xs: 2, sm: 3 }}>
        <Typography
          sx={{
            fontWeight: "700",
          }}
          gutterBottom
        >
          How can you contact us about this notice?
        </Typography>
        <Typography
          variant={"body2"}
          color={"text.secondary"}
          sx={{
            marginBottom: 2,
          }}
        >
          If you have any questions or concerns about the privacy policy please
          contact us.
        </Typography>
        <Typography variant={"subtitle2"}>
          <Link
            href={`mailto:${company.contactEmail}`}
            underline={"none"}
            color={"text.primary"}
          >
            {company.contactEmail}
          </Link>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleOnPrint}
          sx={{ mt: theme.spacing(3) }}
        >
          Print
        </Button>
      </Box>
    </Box>
  );
};

export default ContactCard;
