import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Main from "layouts/Main";
import Container from "components/Container";
import { Form } from "./components";

import { signOut } from "shared/authentication";

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/sign-out") {
      signOut();
    } else {
      const token = localStorage.getItem("token");

      if (token && token.length > 0) {
        const paramIntent = localStorage.getItem("paramIntent");
        const url =
          paramIntent && paramIntent === "buy" ? "/user/cart" : "/user";

        navigate(url);
      }
    }
  }, [location, navigate]);

  return (
    <Main>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
      >
        <Container>
          <Grid container spacing={6}>
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"center"}
              xs={12}
              md={6}
            >
              <Form />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default SignIn;
