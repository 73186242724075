import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      borderRadius={2}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        mt: isMd ? "25vh" : "15vh",
      }}
    >
      <Box sx={{ pt: theme.spacing(3) }}>
        <CircularProgress size={isMd ? "8rem" : "6rem"} color={"primary"} />
        <Box component={"span"} sx={{ textAlign: "center" }}>
          <Typography
            variant={"h4"}
            color={"primary"}
            sx={{ mt: theme.spacing(2) }}
          >
            Loading...
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;
