import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Main from "layouts/Main";
import Container from "components/Container";
import {
  GetStarted,
  Pricing,
  QuickStart,
  Services,
  Hero,
  Partners,
} from "./components";

const IndexView = () => {
  const theme = useTheme();
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <Hero />
        <Container>
          <Services />
        </Container>
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: "repeat-x",
            position: "relative",
          }}
        >
          <Container maxWidth={600}>
            <QuickStart />
          </Container>
          <Container>
            <Partners />
          </Container>
          <Container>
            <Pricing />
          </Container>
        </Box>
        <Container>
          <GetStarted />
        </Container>
      </Main>
    </Box>
  );
};

export default IndexView;
