import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { Form, Information } from "./components";

const Client = ({ clientData, setClientData, isMd }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={{ xs: 2, md: 8 }} sx={{ maxWidth: 1200 }}>
      <Grid item xs={12} md={6}>
        <Form
          clientData={clientData}
          setClientData={setClientData}
          isMd={isMd}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Fragment>
          <Divider
            variant={"middle"}
            sx={{ display: isMd ? "none" : null, mb: theme.spacing(3) }}
          />
          <Information clientData={clientData} />
        </Fragment>
      </Grid>
    </Grid>
  );
};

Client.propTypes = {
  clientData: PropTypes.shape({}).isRequired,
  setClientData: PropTypes.func.isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Client;
