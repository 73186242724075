import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import User from "layouts/User";

import { Form } from "./components";

const UserTicketCreate = () => {
  const theme = useTheme();

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 300px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
        sx={{ p: theme.spacing(4) }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Typography noWrap={true} variant={"h4"}>
              Support
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Form />
          </Grid>
        </Grid>
      </Box>
    </User>
  );
};

export default UserTicketCreate;
