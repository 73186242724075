import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { Image, Details, Orders } from "./components";

import Container from "components/Container";

import { printer, subscriptions } from "constants/stripe";
import { urls, mimeType } from "constants/urls";

const {
  REACT_APP_HTTP_SCHEME,
  REACT_APP_WWW_HOST,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
} = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Form = () => {
  const [quantity, setQuantity] = useState(1);
  const [subscription, setSubscription] = useState(subscriptions[0].term);
  const [loading, setLoading] = useState(false);

  const handlePurchaseClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    const stripe = await stripePromise;
    const token = localStorage.getItem("token");
    const sub = subscriptions.find((s) => s.term === subscription);
    const body = {
      success_url: `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/user/cart-complete`,
      cancel_url: `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/user/cart`,
      line_items: [
        { price: sub.stripePriceId, quantity },
        { price: printer.priceId, quantity },
      ],
    };

    const response = await fetch(urls.stripeSessions, {
      method: "POST",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const session = await response.json();
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    } else {
      setLoading(false);
    }
  };

  return (
    <Container paddingX={0}>
      <Box>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={7}>
            <Image />
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Details
                  quantity={quantity}
                  setQuantity={setQuantity}
                  subscription={subscription}
                  setSubscription={setSubscription}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Orders
                  handlePurchaseClick={handlePurchaseClick}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Form;
