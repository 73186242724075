const defaultTimeZone = "Eastern Time (US & Canada)";
const timeZones = [
  "Atlantic Time (Canada)",
  defaultTimeZone,
  "Central Time (US & Canada)",
  "Mountain Time (US & Canada)",
  "Pacific Time (US & Canada)",
  "Alaska",
  "Hawaii",
];

export { defaultTimeZone, timeZones };
