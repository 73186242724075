import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Main from 'layouts/Main';
import Container from 'components/Container';

const NotFound = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  localStorage.removeItem("paramIntent");
  localStorage.removeItem("paramSource");

  return (
    <Main>
      <Box
        bgcolor={theme.palette.alternate.main}
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <Grid container>
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"center"}
              xs={12}
              md={7}
            >
              <Box>
                <Typography
                  variant="h1"
                  component={"h1"}
                  align={isMd ? "left" : "center"}
                  sx={{ fontWeight: 700 }}
                  color={"secondary"}
                >
                  Success
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  color="text.secondary"
                  align={isMd ? "left" : "center"}
                >
                  Your corder has been received and we will notify you when it ships.
                  <br />
                  If you need assistance, please{" "}
                  <Link href={"mailto:support@eatabit.com?subject=Order question"} underline="none">
                    email us
                  </Link>
                </Typography>
                <Box
                  marginTop={4}
                  display={"flex"}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  <Button
                    component={RouterLink}
                    variant="contained"
                    color="primary"
                    size="large"
                    to={"/user"}
                  >
                    Back home
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default NotFound;
