import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";

import { urls, mimeType, httpCodes } from "constants/urls";
import { defaultTimeZone, timeZones } from "constants/printer";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(1, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify a name"),
  sound: yup
    .number()
    .min(0, "Please enter a valid sound")
    .max(2, "Please enter a valid sound")
    .required("Please enter a valid sound"),
  light: yup
    .number()
    .min(0, "Please enter a valid light")
    .max(2, "Please enter a valid light")
    .required("Please enter a valid light"),
  time_zone: yup
    .string()
    .trim()
    .test("is valid", "Please specify a time zone", (value) =>
      timeZones.includes(value)
    )
    .required("Please specify a time zone"),
});

const labelsSound = ["Off", "Medium", "High"];
const marksSound = [
  {
    value: 0,
    label: "Off",
  },
  {
    value: 1,
    label: "Medium",
  },
  {
    value: 2,
    label: "High",
  },
];
const labelsLight = ["Low", "Medium", "High"];
const marksLight = [
  {
    value: 0,
    label: "Low",
  },
  {
    value: 1,
    label: "Medium",
  },
  {
    value: 2,
    label: "High",
  },
];

const Form = ({
  clientPrinterData: {
    id: clientPrinterId,
    attributes: { name },
  },
  setClientPrinterData,
  printerData: {
    id: printerId,
    attributes: { autoprint, light, sound, tag, time_zone },
  },
  setPrinterData,
  isMd,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [alertVisible, setAlertVisible] = useState(false);

  const initialValues = {
    name,
    autoprint,
    light,
    sound,
    tag: tag || "",
    time_zone: time_zone || defaultTimeZone,
  };

  const onSubmit = (values) => {
    const token = localStorage.getItem("token");
    const body = {
      data: {
        id: clientPrinterId,
        type: "client-printers",
        attributes: {
          name: values.name,
        },
        relationships: {
          printer: {
            data: {
              id: printerId,
              attributes: {
                autoprint: values.autoprint.toString(),
                sound: values.sound,
                light: values.light,
                tag: values.tag,
                time_zone: values.time_zone,
              },
            },
          },
        },
      },
    };

    fetch(`${urls.clientPrinters}/${clientPrinterId}`, {
      method: "PATCH",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        formik.setSubmitting(false);
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ data, included }) => {
        setClientPrinterData(data);
        setPrinterData(included.find((include) => include.type === "printers"));
        setAlertVisible(true);
      })
      .catch((response) => {
        console.error(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const alert = () => {
    return (
      <Alert
        severity={"success"}
        sx={{ mb: theme.spacing(3) }}
        onClose={() => {
          setAlertVisible(!alertVisible);
        }}
      >
        <AlertTitle>Success</AlertTitle>
        Printer successfully updated.
      </Alert>
    );
  };

  return (
    <Box paddingTop={theme.spacing(4)}>
      {alertVisible ? alert() : null}
      <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label={"Printer name *"}
              variant="outlined"
              name={"name"}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={formik.values.autoprint.toString() === "true"}
                  onChange={(event) =>
                    formik.setFieldValue("autoprint", event.target.value)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  value={(!(formik.values.autoprint === "true")).toString()}
                />
              }
              label={
                <Typography variant="subtitle1" fontWeight={700}>
                  Autoprint
                </Typography>
              }
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: "auto" }}>
              <Typography id={"input-slider"} fontWeight={700} gutterBottom>
                Volume
              </Typography>
              <Stack
                spacing={2}
                direction={"row"}
                sx={{ mb: 1 }}
                alignItems={"center"}
              >
                <VolumeDown />
                <Slider
                  aria-label={"Volume"}
                  defaultValue={formik.values.sound}
                  value={formik.values.sound}
                  onChange={(event) =>
                    formik.setFieldValue("sound", event.target.value)
                  }
                  marks={marksSound}
                  valueLabelDisplay={"auto"}
                  step={1}
                  getAriaValueText={() => {
                    return labelsSound[formik.values.sound];
                  }}
                  max={2}
                  min={0}
                />
                <VolumeUp />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: "auto" }}>
              <Typography id={"input-slider"} fontWeight={700} gutterBottom>
                Brightness
              </Typography>
              <Stack
                spacing={2}
                direction={"row"}
                sx={{ mb: 1 }}
                alignItems={"center"}
              >
                <BrightnessLowIcon />
                <Slider
                  aria-label={"Volume"}
                  defaultValue={formik.values.light}
                  value={formik.values.light}
                  onChange={(event) =>
                    formik.setFieldValue("light", event.target.value)
                  }
                  marks={marksLight}
                  valueLabelDisplay={"auto"}
                  step={1}
                  getAriaValueText={() => {
                    return labelsLight[formik.values.light];
                  }}
                  max={2}
                  min={0}
                />
                <BrightnessHighIcon />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Tag"}
              variant="outlined"
              name={"tag"}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.tag}
              error={formik.touched.tag && Boolean(formik.errors.tag)}
              helperText={formik.touched.tag && formik.errors.tag}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id={"time-zone-label"}>Time zone</InputLabel>
              <Select
                fullWidth
                labelId={"time-zone-label"}
                id={"time_zone"}
                label={"time_zone"}
                value={formik.values.time_zone}
                onChange={(event) =>
                  formik.setFieldValue("time_zone", event.target.value)
                }
                error={
                  formik.touched.time_zone && Boolean(formik.errors.time_zone)
                }
              >
                {timeZones.map((timeZone, i) => (
                  <MenuItem value={timeZone} key={i}>
                    {timeZone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"flex-end"}
              width={1}
              margin={"0 auto"}
            >
              <Button
                variant={"contained"}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(-1);
                }}
                sx={{ mr: theme.spacing(2) }}
                color={"secondary"}
                fullWidth={!isMd}
              >
                Back
              </Button>
              <LoadingButton
                loading={formik.isSubmitting}
                size={"large"}
                variant={"contained"}
                type={"submit"}
                sx={{ mt: isMd ? null : theme.spacing(2) }}
                fullWidth
              >
                Save
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  clientPrinterData: PropTypes.shape({}).isRequired,
  setClientPrinterData: PropTypes.func.isRequired,
  printerData: PropTypes.shape({}).isRequired,
  setPrinterData: PropTypes.func.isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Form;
