import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "connectivity", label: "Connectivity", minWidth: 100 },
  { id: "jobs_count", label: "Jobs count", minWidth: 100 },
  { id: "model", label: "Model", minWidth: 100 },
  {
    id: "enabled",
    label: "Enabled",
    minWidth: 100,
    format: (value) =>
      value ? (
        <CheckIcon sx={{ color: "green" }} />
      ) : (
        <CloseIcon sx={{ color: "red" }} />
      ),
  },
];

const Printers = ({
  printersData,
  searchParams,
  setSearchParams,
  meta: { total },
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setSearchParams({ ...searchParams, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams({ ...searchParams, page: 0, per: +event.target.value });
  };

  const handleOnClick = (id) => (event) => {
    event.preventDefault();
    navigate(`/user/printers/${id}`);
  };

  return (
    <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {printersData.length > 0 ? (
            <TableBody>
              {printersData.map((printerData, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    onClick={handleOnClick(printerData.id)}
                    sx={{ cursor: "pointer" }}
                  >
                    {columns.map((column) => {
                      const value = printerData.attributes[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format &&
                          (typeof value === "number" ||
                            typeof value === "boolean")
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align={"center"} colSpan={columns.length}>
                  <Typography variant={"h5"} sx={{ py: theme.spacing(4) }}>
                    No search results
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component={"div"}
        count={total}
        rowsPerPage={searchParams.per}
        page={searchParams.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

Printers.propTypes = {
  meta: PropTypes.shape({}).isRequired,
  printersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  setSearchParams: PropTypes.func.isRequired,
};

export default Printers;
