import React from "react";
import Box from "@mui/material/Box";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const Image = () => {
  return (
    <Box>
      <Box
        sx={{
          marginBottom: 2,
          width: 1,
          height: "auto",
          "& img": {
            width: 1,
            height: 1,
            objectFit: "cover",
            borderRadius: 2,
          },
        }}
      >
        <img
          src={`${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/product/printer.png`}
          alt={"Eatabit Model 10 printer"}
        />
      </Box>
    </Box>
  );
};

export default Image;
