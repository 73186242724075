import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import Paper from "@mui/material/Paper";

import "@fontsource/vt323";

const receiptSx = {
  width: "340px",
  color: "#333",
  fontFamily: "vt323",
  fontSize: "0.98rem",
  padding: "1rem",
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#fff",
  paperLarge: {
    fontSize: "1.96rem",
  },
  paperRight: {
    float: "right",
  },
  paperBold: {
    fontWeight: 900,
  },
};

const Receipt = ({
  jobData: {
    attributes: { body },
  },
}) => {
  const formattedBody = body
    .replace(/\n/g, "<br>")
    .replace(/<b>/g, `<span class="paperBold">`)
    .replace(/<\/b>/g, "</span>")
    .replace(/<lg>/g, `<span class="paperLarge">`)
    .replace(/<\/lg>/g, "</span>")
    .replace(/<right>/g, `<span class="paperRight">`)
    .replace(/<\/right>/g, "</span><br>");

  return (
    <Paper sx={receiptSx} elevation={24} square>
      {parse(formattedBody)}
    </Paper>
  );
};

Receipt.propTypes = {
  jobData: PropTypes.shape({}).isRequired,
};

export default Receipt;
