import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SupportIcon from "@mui/icons-material/Support";
import PrintIcon from "@mui/icons-material/Print";
import WifiIcon from "@mui/icons-material/Wifi";
import CellTowerIcon from "@mui/icons-material/CellTower";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TagIcon from "@mui/icons-material/Tag";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";

const Information = ({
  clientPrinterData: {
    attributes: { connectivity, jobs_count, created_at, updated_at },
  },
  printerData: {
    id: printerId,
    attributes: { hardware_version, last_heartbeat_at },
  },
}) => {
  const {
    attributes: { company_name },
  } = JSON.parse(localStorage.getItem("user"));

  return (
    <Fragment>
      <Box bgcolor={"alternate.main"}>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            padding: 2,
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <PrintIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Printer ID"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component={"span"}
                    variant={"body2"}
                    color={"text.primary"}
                  >
                    {printerId}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              {connectivity === "wifi" ? <WifiIcon /> : <CellTowerIcon />}
            </ListItemAvatar>
            <ListItemText
              primary="Connectivity"
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {connectivity}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <ReceiptIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Jobs count"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {jobs_count}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TagIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Model"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {hardware_version}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <CalendarMonthIcon />
            </ListItemAvatar>
            <ListItemText
              primary={`Assigned to ${company_name}`}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {formatDistanceToNow(parseISO(created_at), {
                      addSuffix: true,
                    })}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <EditIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Last update"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {formatDistanceToNow(parseISO(updated_at), {
                      addSuffix: true,
                    })}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <VisibilityIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Last seen"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {last_heartbeat_at
                      ? formatDistanceToNow(parseISO(last_heartbeat_at), {
                          addSuffix: true,
                        })
                      : "not online yet"}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
        </List>
      </Box>
      <Box marginTop={4} display={"flex"} justifyContent={"flex-start"}>
        <Box>
          <Typography paragraph>Need Support for this Printer?</Typography>
          <Stack direction={"row"} spacing={2} marginTop={0.5}>
            <Button
              variant={"outlined"}
              startIcon={<SupportIcon />}
              component={RouterLink}
              to={`/user/support?printerId=${printerId}`}
            >
              Create ticket
            </Button>
          </Stack>
        </Box>
      </Box>
    </Fragment>
  );
};

Information.propTypes = {
  clientPrinterData: PropTypes.shape({}).isRequired,
  printerData: PropTypes.shape({}).isRequired,
};

export default Information;
