import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { Receipt, Details } from "./components";

const Job = ({ jobData, printerData, isMd }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={{ xs: 2, md: 8 }} sx={{ maxWidth: 1200 }}>
      <Grid item xs={12} md={6}>
        <Receipt jobData={jobData} isMd={isMd} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Fragment>
          <Divider
            variant={"middle"}
            sx={{ display: isMd ? "none" : null, mb: theme.spacing(3) }}
          />
          <Details jobData={jobData} printerData={printerData} isMd={isMd} />
        </Fragment>
      </Grid>
    </Grid>
  );
};

Job.propTypes = {
  jobData: PropTypes.shape({}).isRequired,
  printerData: PropTypes.shape({}).isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Job;
