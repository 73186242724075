import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import TokenIcon from "@mui/icons-material/Token";

const Credentials = ({ accountsData }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    event.preventDefault();
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid
      container
      paddingTop={theme.spacing(4)}
      spacing={{ xs: 2, md: 8 }}
      sx={{ maxWidth: 1200 }}
    >
      <Grid item>
        {accountsData.map((accountData, i) => (
          <Accordion
            expanded={expanded === accountData.id}
            onChange={handleChange(accountData.id)}
            key={i}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel1bh-content"}
              id={"panel1bh-header"}
            >
              <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 800 }}>
                {accountData.attributes.development
                  ? "Development"
                  : "Production"}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Expand to reveal secrets
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 460,
                  bgcolor: "background.paper",
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: 2,
                }}
              >
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <FingerprintIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={"Sid"}
                    primaryTypographyProps={{ fontWeight: 800 }}
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {accountData.attributes.sid}
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <TokenIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={"Token"}
                    primaryTypographyProps={{ fontWeight: 800 }}
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {accountData.attributes.token}
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

Credentials.propTypes = {
  accountsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Credentials;
