import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { printer, subscriptions } from "constants/stripe";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Details = ({ quantity, setQuantity, subscription, setSubscription }) => {
  const theme = useTheme();

  const quantityLimit = 25;

  return (
    <Box>
      <Box
        padding={1}
        display={"inline-flex"}
        borderRadius={1}
        bgcolor={"primary.main"}
        marginBottom={1}
      >
        <Typography sx={{ color: "common.white", lineHeight: 1 }}>
          {`Model ${printer.model}`}
        </Typography>
      </Box>
      <Typography variant={"h4"} fontWeight={700}>
        {printer.name}
      </Typography>
      <Box marginY={3}>
        <Box display={"flex"}>
          <Typography variant={"h4"} fontWeight={700} color={"secondary"}>
            {formatter.format(printer.price)}
          </Typography>
        </Box>
      </Box>
      <Typography variant={"subtitle1"} color={"text.secondary"}>
        Eatabit printers have been designed specifically for restaurant and
        retail settings where efficiency and ease of use are a must.
      </Typography>
      <Box marginY={3}>
        <Box>
          <Typography>
            Subscription:{" "}
            <Typography component={"span"} fontWeight={700}>
              {subscription}
            </Typography>
          </Typography>
          <Stack direction={"row"} spacing={1} marginTop={0.5}>
            {subscriptions.map((sub, i) => (
              <Box
                key={i}
                onClick={() => setSubscription(sub.term)}
                sx={{
                  borderRadius: 1,
                  padding: 1,
                  border: `2px solid ${
                    subscription === sub.term
                      ? theme.palette.primary.main
                      : theme.palette.divider
                  }`,
                  cursor: "pointer",
                }}
              >
                <Typography>
                  {sub.term}: {sub.title}
                </Typography>
              </Box>
            ))}
          </Stack>
          * Subscriptions are free for the first 30 days
        </Box>
        <Box marginTop={3}>
          <Typography>
            Quantity:{" "}
            <Typography component={"span"} fontWeight={700}>
              {quantity}
            </Typography>
          </Typography>
          <Stack direction={"row"} spacing={2} marginTop={0.5}>
            <Box
              onClick={(event) => {
                event.preventDefault();
                setQuantity(quantity - 1 >= 1 ? quantity - 1 : 1);
              }}
              sx={{
                borderRadius: 1,
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.divider}`,
                cursor: quantity === 1 ? "not-allowed" : "pointer",
              }}
            >
              <Typography
                color={quantity === 1 ? "text.secondary" : "text.primary"}
              >
                - Remove
              </Typography>
            </Box>
            <Box
              onClick={(event) => {
                event.preventDefault();
                setQuantity(
                  quantity + 1 <= quantityLimit ? quantity + 1 : quantityLimit
                );
              }}
              sx={{
                borderRadius: 1,
                paddingY: 1,
                paddingX: 2,
                border: `1px solid ${theme.palette.divider}`,
                cursor: quantity === quantityLimit ? "not-allowed" : "pointer",
              }}
            >
              <Typography
                color={
                  quantity === quantityLimit ? "text.secondary" : "text.primary"
                }
              >
                + Add
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

Details.propTypes = {
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  subscription: PropTypes.string.isRequired,
  setSubscription: PropTypes.func.isRequired,
};

export default Details;
