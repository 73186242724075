import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import User from "layouts/User";

import { Printer, Navigation } from "./components";
import { urls, mimeType, httpCodes } from "constants/urls";
import { Loading } from "shared/Loading";

const UserPrinter = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [clientPrinterData, setClientPrinterData] = useState({});
  const [printerData, setPrinterData] = useState({});

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${urls.clientPrinters}/${id}`, {
      method: "GET",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ data, included }) => {
        setClientPrinterData(data);
        setPrinterData(included.find((include) => include.type === "printers"));
        setLoading(false);
      })
      .catch((response) => {
        console.error(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  }, [id, navigate]);

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 300px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
        sx={{ p: theme.spacing(4) }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            <Grid item container>
              <Typography noWrap={true} variant={"h4"}>
                Printer
              </Typography>
            </Grid>
            <Grid item container>
              <Typography noWrap={true} variant={"h5"} sx={{ fontWeight: 800 }}>
                {printerData.id}
              </Typography>
            </Grid>
            <Grid item container>
              <Box width={"100%"} sx={{ py: theme.spacing(4) }}>
                <Navigation
                  clientPrinterData={clientPrinterData}
                  printerData={printerData}
                />
                <Divider sx={{ mt: theme.spacing(3) }} />
              </Box>
            </Grid>
            <Grid item container>
              <Printer
                printerData={printerData}
                setPrinterData={setPrinterData}
                clientPrinterData={clientPrinterData}
                setClientPrinterData={setClientPrinterData}
                isMd={isMd}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </User>
  );
};

export default UserPrinter;
