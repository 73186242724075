const authenticated = () => {
  const token = localStorage.getItem("token");

  return token && token.length > 1;
};

const permissionPurchasePrinter = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  return userData?.attributes?.permissions.find(
    ({ purchase_printer }) => purchase_printer
  )?.purchase_printer;
};

const userIsAdmin = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  return userData?.attributes?.role === "admin";
};

const signOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("cart");
};

const handleErrorResponse = (response) => {
  if (response.ok) {
    return;
  }

  if (response.status === 401) {
    signOut();
    window.location = "/sign-in";
  } else {
    console.error(response);
  }
};

export {
  authenticated,
  permissionPurchasePrinter,
  userIsAdmin,
  signOut,
  handleErrorResponse,
};
