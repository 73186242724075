import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

const Orders = ({ handlePurchaseClick, loading }) => {
  return (
    <Box>
      <Stack spacing={2} marginY={{ xs: 2, sm: 4 }}>
        <Divider />
        <LoadingButton
          variant={"contained"}
          size={"large"}
          onClick={handlePurchaseClick}
          fullWidth
          loading={loading}
          disabled={loading}
        >
          Add to cart
        </LoadingButton>
      </Stack>
    </Box>
  );
};

Orders.propTypes = {
  handlePurchaseClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Orders;
