import React, { useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";

import { urls, mimeType, httpCodes } from "constants/urls";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have a minimum length of 8"),
});

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(location.search);

  localStorage.setItem("paramIntent", params.get("intent"));
  localStorage.setItem("paramSource", params.get("source"));

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values) => {
    setLoading(true);

    const paramIntent = localStorage.getItem("paramIntent");
    const successUrl =
      paramIntent && paramIntent === "buy" ? "/user/cart" : "/user";
    const data = {
      data: {
        type: "auth",
        attributes: {
          email: values.email,
          password: values.password,
        },
      },
      meta: Object.fromEntries(params),
    };

    fetch(urls.signIn, {
      method: "POST",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        setLoading(false);
        if (response.status === httpCodes.unauthorized) {
          formik.setErrors({
            email: "Authentication failed",
            password: "Authentication failed",
          });
          throw response;
        } else {
          return response.json();
        }
      })
      .then(({ jwt, user: { data: userData } }) => {
        localStorage.setItem("token", jwt);
        localStorage.setItem("user", JSON.stringify(userData));
        navigate(successUrl);
      })
      .catch((response) => {
        console.error(response);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Login
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Welcome back
        </Typography>
        <Typography color="text.secondary">
          Login to manage your account.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Enter your password
                </Typography>
              </Box>
              <Typography variant={"subtitle2"}>
                <Link
                  component={RouterLink}
                  color={"primary"}
                  to={"/password-recover"}
                  underline={"none"}
                >
                  Forgot your password?
                </Link>
              </Typography>
            </Box>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Don't have an account yet?{" "}
                  <Link
                    component={RouterLink}
                    color={"primary"}
                    to={`/sign-up?${params.toString()}`}
                    underline={"none"}
                  >
                    Sign up here.
                  </Link>
                </Typography>
              </Box>
              <LoadingButton
                loading={loading}
                size={"large"}
                variant={"contained"}
                type={"submit"}
              >
                Sign in
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
