import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import NavItem from "./components/NavItem";

import { company } from "constants/company";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const { menu: menuPages } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title={company.name}
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={
              mode === "light"
                ? `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_light.svg`
                : `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_dark.svg`
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={"Menu"} items={menuPages} />
        </Box>
        <Box marginTop={2}>
          <Button
            size={"large"}
            variant={"outlined"}
            fullWidth
            component={"a"}
            href={"https://documentation.eatabit.io"}
            target={"_blank"}
          >
            Documentation
          </Button>
        </Box>
        <Box marginTop={1}>
          <Button
            size={"large"}
            variant={"contained"}
            color={"primary"}
            fullWidth
            component={RouterLink}
            to={"/sign-in"}
          >
            Purchase now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
