import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PrintIcon from "@mui/icons-material/Print";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import SupportIcon from "@mui/icons-material/Support";
import DataObjectIcon from "@mui/icons-material/DataObject";
import KeyIcon from "@mui/icons-material/Key";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const linkItems = [
  {
    groupTitle: "Dashboard",
    id: "dashboard",
    pages: [
      {
        title: "Jobs",
        to: "/user/jobs",
        icon: <ReceiptIcon />,
      },
      {
        title: "Printers",
        to: "/user/printers",
        icon: <PrintIcon />,
      },
      {
        title: "Account",
        to: "/user/client",
        icon: <BusinessIcon />,
      },
      {
        title: "User",
        to: "/user/account",
        icon: <PersonIcon />,
      },
      {
        title: "Credentials",
        to: "/user/credentials",
        icon: <KeyIcon />,
      },
      {
        title: "Cart",
        to: "/user/cart",
        icon: <ShoppingCartIcon />,
      },
    ],
  },
  {
    groupTitle: "Support",
    id: "support",
    pages: [
      {
        title: "Support",
        to: "/user/support",
        icon: <SupportIcon />,
      },
    ],
  },
];

const SidebarNav = () => {
  const theme = useTheme();

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  return (
    <Box padding={2}>
      {linkItems.map((item, i) => (
        <Box key={i} marginBottom={3}>
          <Typography
            variant="caption"
            color={"text.secondary"}
            sx={{
              fontWeight: 700,
              textTransform: "uppercase",
              marginBottom: 1,
              display: "block",
            }}
          >
            {item.groupTitle}
          </Typography>
          <Box>
            {item.pages.map((p, i) => (
              <Box marginBottom={1 / 2} key={i}>
                <Button
                  component={RouterLink}
                  to={p.to}
                  fullWidth
                  sx={{
                    justifyContent: "flex-start",
                    color:
                      activeLink === p.to
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                  }}
                  startIcon={p.icon || null}
                >
                  {p.title}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      <Box marginBottom={3}>
        <Typography
          variant="caption"
          color={"text.secondary"}
          sx={{
            fontWeight: 700,
            textTransform: "uppercase",
            marginBottom: 1,
            display: "block",
          }}
        >
          Documentation
        </Typography>
        <Box>
          <Box marginBottom={1 / 2}>
            <Button
              component={"a"}
              target={"_blank"}
              href={"https://documentation.eatabit.io"}
              fullWidth
              sx={{
                justifyContent: "flex-start",
                color: "text.primary",
              }}
              startIcon={<DataObjectIcon />}
            >
              Api
            </Button>
          </Box>
        </Box>
      </Box>
      <Button
        variant={"contained"}
        color={"primary"}
        fullWidth
        component={RouterLink}
        to={"/sign-out"}
      >
        Sign out
      </Button>
    </Box>
  );
};

export default SidebarNav;
