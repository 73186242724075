import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { Form, Information } from "./components";

const Account = ({ userData, setUserData, isMd }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={{ xs: 2, md: 8 }} sx={{ maxWidth: 1200 }}>
      <Grid item xs={12} md={6}>
        <Form userData={userData} setUserData={setUserData} isMd={isMd} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Fragment>
          <Divider
            variant={"middle"}
            sx={{ display: isMd ? "none" : null, mb: theme.spacing(3) }}
          />
          <Information userData={userData} />
        </Fragment>
      </Grid>
    </Grid>
  );
};

Account.propTypes = {
  userData: PropTypes.shape({}).isRequired,
  setUserData: PropTypes.func.isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Account;
