import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import User from "layouts/User";

import { Client } from "./components";
import { urls, mimeType, httpCodes } from "constants/urls";
import { Loading } from "shared/Loading";

const UserClient = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState({});

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(urls.client, {
      method: "GET",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ data, included }) => {
        setClientData(data);
        setLoading(false);
      })
      .catch((response) => {
        console.error(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  }, [id, navigate]);

  return (
    <User>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 300px)"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        height={1}
        sx={{ p: theme.spacing(4) }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            <Grid item container>
              <Typography noWrap={true} variant={"h4"}>
                Account
              </Typography>
            </Grid>
            <Grid item container>
              <Typography noWrap={true} variant={"h5"} sx={{ fontWeight: 800 }}>
                {clientData.name}
              </Typography>
            </Grid>
            <Grid item container>
              <Client
                clientData={clientData}
                setClientData={setClientData}
                isMd={isMd}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </User>
  );
};

export default UserClient;
