import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { urls, mimeType, httpCodes } from "constants/urls";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(1, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify a name"),
  contact_tech: yup
    .string()
    .trim()
    .email("Please enter a valid email")
    .required("Contact email is required"),
});

const Form = ({
  clientData: {
    id: clientId,
    attributes: { name, contact_tech },
  },
  setClientData,
  isMd,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [alertVisible, setAlertVisible] = useState(false);

  const initialValues = {
    name,
    contact_tech: contact_tech || "",
  };

  const onSubmit = (values) => {
    const token = localStorage.getItem("token");
    const body = {
      data: {
        id: clientId,
        type: "clients",
        attributes: {
          name: values.name,
          contact_tech: values.contact_tech,
        },
      },
    };

    fetch(urls.client, {
      method: "PATCH",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        formik.setSubmitting(false);
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(({ data }) => {
        setClientData(data);
        setAlertVisible(true);
      })
      .catch((response) => {
        console.error(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const alert = () => {
    return (
      <Alert
        severity={"success"}
        sx={{ mb: theme.spacing(3) }}
        onClose={() => {
          setAlertVisible(!alertVisible);
        }}
      >
        <AlertTitle>Success</AlertTitle>
        Account successfully updated.
      </Alert>
    );
  };

  return (
    <Box paddingTop={theme.spacing(4)}>
      {alertVisible ? alert() : null}
      <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label={"Name *"}
              variant="outlined"
              name={"name"}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Contact email *"}
              variant="outlined"
              name={"contact_tech"}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.contact_tech}
              error={
                formik.touched.contact_tech &&
                Boolean(formik.errors.contact_tech)
              }
              helperText={
                formik.touched.contact_tech && formik.errors.contact_tech
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"flex-end"}
              width={1}
              margin={"0 auto"}
            >
              <Button
                variant={"contained"}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(-1);
                }}
                sx={{ mr: theme.spacing(2) }}
                color={"secondary"}
                fullWidth={!isMd}
              >
                Back
              </Button>
              <LoadingButton
                loading={formik.isSubmitting}
                size={"large"}
                variant={"contained"}
                type={"submit"}
                sx={{ mt: isMd ? null : theme.spacing(2) }}
                fullWidth
              >
                Save
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  clientData: PropTypes.shape({}).isRequired,
  setClientData: PropTypes.func.isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Form;
