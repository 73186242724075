import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const mock = [
  {
    title: "Equipment",
    subtext: [
      {
        title: "Terms of delivery",
        subtext: [
          {
            title:
              "Provider shall ship the Equipment to Purchaser within thirty (30) business days of receipt of the initial Purchase Fee to Purchaser’s principal place of business or at such other location as Purchaser may specify in writing to Provider.",
            subtext: [],
          },
          {
            title:
              "All equipment is located in the Eatabit warehouse in Tennessee, USA unless otherwise agreed, the entire order will normally be delivered as on consignment. If applicable, preparation of export documentation is included. Unless not specified by the Purchaser, Ground shipping will be used. Transportation risks and costs are assumed by the Purchaser. If the Purchaser requests delivery in instalments, or to multiple locations, shipping costs will be charged for each consignment.",
            subtext: [],
          },
        ],
      },
      {
        title: "Deviant delivery time",
        subtext: [
          {
            title:
              "The Seller has a duty to notify the Purchaser about any different delivery time. The Purchaser will not be entitled to financial compensation or to cancel the Purchase in the event of delayed delivery.",
            subtext: [],
          },
        ],
      },
      {
        title: "Right to return",
        subtext: [
          {
            title:
              "The Purchaser is not entitled to return or cancel an order for goods without a special agreement to that effect.",
            subtext: [],
          },
        ],
      },
      {
        title: "Accepted delivery",
        subtext: [
          {
            title:
              "A delivery is considered to be accepted once the Purchaser has received the goods. The Seller assumes no responsibility for missing goods after the Purchaser has received the delivery. If the wrong goods have been sent, the Customer is entitled to return the goods free of charge within eight (8) working days.",
            subtext: [],
          },
        ],
      },
      {
        title: "Limited warranty",
        subtext: [
          {
            title:
              "Provider hereby represents and warrants to Purchaser that the Equipment shall be free and clear from any material defects and shall remain in good working order, save and except for the proper installation, operation, and maintenance of the Equipment by Purchaser.",
            subtext: [],
          },
          {
            title:
              "DOA ('Dead On Arrival') is defined and determined by the manufacturer. Eatabit only accepts warranty liability at that time Eatabit has the right to assign warranty claims against producers DOA, warranty terms or applicable warranty conditions. Application for DOA is sent to our customer service and must be made within the time interval specified by the manufacturer and with reference to the manufacturer's conditions.",
            subtext: [],
          },
          {
            title:
              "Any warranty claims that may be made by Purchaser shall be made within twelve (12) months of Purchaser receiving the Equipment and Provider shall repair, replace, or correct any such defect in the Equipment present at the time of delivery of the Equipment to Purchaser or shall remove the defective Equipment and refund the Purchase Fee to Purchaser.",
            subtext: [],
          },
          {
            title:
              "A product which is being returned for warranty repairs or other service/repair must first create a claim with Eatabit Customer Service. A claim can be obtained via the web portal at https://support.eatabit.io. An agreement for priority service and repair can be entered into with the Seller.",
            subtext: [],
          },
          {
            title:
              "The representations and warranties of Provider shall commence on the date of delivery to Purchaser.",
            subtext: [],
          },
          {
            title:
              "Provider and Purchaser hereby agree that any repairs to the Equipment under the representation and warranty period as set out in Article 1.5.3 above shall be conducted by an authorized service representative of Provider. Purchaser shall ship any Equipment subject to a warranty claim to Provider for repair, replacement, or correction any such defect. Purchaser shall be responsible for shipping costs of any Equipment returned under a warranty claim.",
            subtext: [],
          },
          {
            title:
              'Purchaser Acknowledges And Agrees That The Service Is Provided On An "As Is" And "As Available" Basis. None Of Company, Its Affiliates Or Their Respective Officers, Directors, Employees Or Agents (Collectively, The "Company Parties") Guarantees The Timeliness, Accuracy, Completeness, Or Usefulness Of Any The Products Or Service, Or That Use Of The Service Will Be Uninterrupted, Error Free Or Virus Free. The Entire Risk As To The Quality And Performance Of This Website And The Timeliness, Usefulness, Accuracy Or Completeness Of The Products And Services Is Assumed Solely By Purchaser. All Of The Company Parties Hereby Specifically Disclaim Any Representations, Endorsements, Guarantees, Or Warranties, Express Or Implied, Regarding The Products Or Services, Including Without Limitation, The Implied Warranties Of Merchantability And Fitness For A Particular Purpose And Non-Infringement Of Third-Party Rights.Without Limiting The Generality Of The Foregoing, All Of The Company Parties Disclaim Any Warranties With Respect To Any Results That May Be Obtained From The Use Of This Website, The Products Or Services.',
            subtext: [],
          },
        ],
      },
    ],
  },
  {
    title: "Service",
    subtext: [
      {
        title:
          "Company will provide connectivity and communication service for the Equipment (the “Service”). The Service includes connectivity for the Equipment to a network and access to the Equipment API for communication of orders to Equipment. The Purchaser’s sign-up and registration for, or use of, the Service shall be deemed to be an agreement by the Purchaser to abide by all the terms and conditions of this Agreement.",
        subtext: [
          {
            title: "Prohibited activities",
            subtext: [
              {
                title:
                  "Transmission of any sensitive personal information including: Social Security number, State-issued driver’s license number, State-issued identification card number, Passport number, Credit card number, Financial account number in combination with a security code, access code or password that would permit access to the account, Medical or health insurance information.",
                subtext: [],
              },
              {
                title:
                  "Interfere with or cause undue burden on the Service or networks connected to the Service.",
                subtext: [],
              },
              {
                title:
                  "Attempting to bypass security measures provided by the Service.",
                subtext: [],
              },
              {
                title:
                  "Use the Service in a manner that is incompatible with applicable laws or regulations.",
                subtext: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Limited warranty",
    subtext: [
      {
        title:
          "Provider hereby represents and warrants to Purchaser that the Equipment shall be free and clear from any material defects and shall remain in good working order, save and except for the proper installation, operation, and maintenance of the Equipment by Purchaser.",
        subtext: [],
      },
      {
        title:
          "Any warranty claims that may be made by Purchaser shall be made within thirty (30) days of Purchaser receiving the Equipment and Provider shall repair, replace, or correct any such defect in the Equipment present at the time of delivery of the Equipment to Purchaser or shall remove the defective Equipment and refund the Purchase Fee to Purchaser.",
        subtext: [],
      },
    ],
  },
  {
    title: "Equipment and service fees",
    subtext: [
      {
        title: "Forms of payment",
        subtext: [
          {
            title: "We accept the following forms of payment:",
            subtext: [
              {
                title: "Visa",
                subtext: [],
              },
              {
                title: "Mastercard",
                subtext: [],
              },
              {
                title: "American Express",
                subtext: [],
              },
              {
                title: "Discover",
                subtext: [],
              },
            ],
          },
        ],
      },
      {
        title:
          "Purchaser agrees to provide current and complete payment information for all purchases.",
        subtext: [],
      },
      {
        title:
          "Purchaser agrees to promptly update account and payment information in order to facilitate prompt processing of payments.",
        subtext: [],
      },
      {
        title: "All payments will be made in US dollars.",
        subtext: [],
      },
      {
        title:
          "Seller reserves the right to refuse any purchase without redress.",
        subtext: [],
      },
    ],
  },
  {
    title: "Limitation on liability",
    subtext: [
      {
        title:
          "Provider and Purchaser hereby agree that in no event shall Provider be liable for any indirect, incidental, punitive, or consequential damages for loss of profits, revenue, or use incurred by Purchaser or any third party, whether in an action in contract, tort, or otherwise even if advised of the possibility of such damages.",
        subtext: [],
      },
      {
        title:
          "Provider’s liability for any such damages arising out of or in connection to this Agreement shall in no event exceed the Purchase Fee of the defective Equipment.",
        subtext: [],
      },
      {
        title: "General provisions",
        subtext: [
          {
            title:
              "Except as otherwise set out herein, Provider and Purchaser shall be responsible for their own legal and other expenses incurred in connection with the negotiation, preparation, execution, delivery, and performance of this Agreement.",
            subtext: [],
          },
          {
            title:
              "This Agreement shall inure to the benefit of, and be binding upon, each of Provider and Purchaser, and their respective successors and permitted assigns.",
            subtext: [],
          },
          {
            title:
              "This Agreement (together with all other agreements or documents executed by the Provider and Purchaser as may be contemplated by this Agreement) constitutes the entire integrated agreement between Provider and Purchaser pertaining to the subject matter herein and supersedes all prior agreements, understandings, negotiations, and discussions, whether oral or written including, without limitation, any confidentiality agreements that may have been entered into between Provider and Purchaser prior to the date hereof.",
            subtext: [],
          },
          {
            title:
              "Any provision of this Agreement which, as it may relate to Provider or Purchaser, is prohibited or unenforceable in any jurisdiction shall, as to that jurisdiction, be ineffective to the extent of such prohibition or unenforceability and shall be severed from the balance of this Agreement, all without affecting the remaining provisions of this Agreement or affecting the validity or enforceability of such provision in any other jurisdiction.",
            subtext: [],
          },
          {
            title:
              "This Agreement shall be governed by and construed in accordance with the laws of the State of South Carolina.",
            subtext: [],
          },
        ],
      },
    ],
  },
];

const Section = ({ title, subtext }) => (
  <li>
    <Typography variant={"h7"} gutterBottom sx={{ fontWeight: "medium" }}>
      {title}
    </Typography>
    <ol>
      {subtext.map((item, i) => (
        <Section {...item} key={i} />
      ))}
    </ol>
  </li>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const Content = () => {
  return (
    <Box>
      <Typography component={"p"} color={"text.secondary"} paragraph={true}>
        This Terms of Service Agreement (this “Agreement”) constitute a legally
        binding agreement between Pocket Restaurant LLC d/b/a Eatabit, a South
        Carolina limited liability company having its physical place of business
        at 1114 Harbor Trace Circle, Charleston, South Carolina 29412
        (“Provider”) and you, (“Purchaser”).
      </Typography>
      <Typography
        variant={"h6"}
        gutterBottom
        sx={{ fontWeight: "medium" }}
        paragraph={true}
      >
        THE PARTIES HEREBY AGREE AS FOLLOWS:
      </Typography>
      <ol>
        {mock.map((item, i) => (
          <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
            <Section {...item} />
          </Box>
        ))}
      </ol>
    </Box>
  );
};

export default Content;
