import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Container from "components/Container";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;
const mock = [
  `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/partners/data_dreamers.png`,
  `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/partners/deliver_logic.png`,
  `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/partners/delivery_com.png`,
  `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/partners/leafly.png`,
  `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/partners/mealeo.png`,
  `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/partners/zuppler.png`,
];

const Partners = () => {
  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            gutterBottom
            align={"center"}
            variant={"h4"}
            fontWeight={700}
          >
            Easily integrate with Eatabit
          </Typography>
          <Typography color={"text.secondary"} align={"center"} variant={"h6"}>
            System integrators can get printers up and running in no time with
            our docs and development environment. Retail operators can team up
            with established online sellers who have Eatabit already built-in.
          </Typography>
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent={"center"}>
          {mock.map((item, i) => (
            <Box marginTop={2} marginRight={4} key={i}>
              <Box component={"img"} src={item} alt={"..."} />
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Partners;
