import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PrintIcon from "@mui/icons-material/Print";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Information = ({
  clientData: {
    attributes: { name, printers_count, created_at, contact_tech },
  },
}) => {
  return (
    <Box bgcolor={"alternate.main"}>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          padding: 2,
          borderRadius: 2,
          boxShadow: 2,
        }}
      >
        <ListItem alignItems={"flex-start"}>
          <ListItemAvatar>
            <BusinessIcon />
          </ListItemAvatar>
          <ListItemText
            primary={"Account"}
            primaryTypographyProps={{ fontWeight: 800 }}
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component={"span"}
                variant={"body2"}
                color={"text.primary"}
              >
                {name}
              </Typography>
            }
          />
        </ListItem>
        <Divider variant={"inset"} component={"li"} />
        <ListItem alignItems={"flex-start"}>
          <ListItemAvatar>
            <PrintIcon />
          </ListItemAvatar>
          <ListItemText
            primary={"Printers count"}
            primaryTypographyProps={{ fontWeight: 800 }}
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component={"span"}
                variant={"body2"}
                color={"text.primary"}
              >
                {printers_count}
              </Typography>
            }
          />
        </ListItem>
        <Divider variant={"inset"} component={"li"} />
        <ListItem alignItems={"flex-start"}>
          <ListItemAvatar>
            <CalendarMonthIcon />
          </ListItemAvatar>
          <ListItemText
            primary={"Account created"}
            primaryTypographyProps={{ fontWeight: 800 }}
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component={"span"}
                variant={"body2"}
                color={"text.primary"}
              >
                {formatDistanceToNow(parseISO(created_at), {
                  addSuffix: true,
                })}
              </Typography>
            }
          />
        </ListItem>
        <Divider variant={"inset"} component={"li"} />
        <ListItem alignItems={"flex-start"}>
          <ListItemAvatar>
            <MailOutlineIcon />
          </ListItemAvatar>
          <ListItemText
            primary={"Contact email"}
            primaryTypographyProps={{ fontWeight: 800 }}
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component={"span"}
                variant={"body2"}
                color={"text.primary"}
              >
                {contact_tech || ""}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

Information.propTypes = {
  clientData: PropTypes.shape({}).isRequired,
};

export default Information;
