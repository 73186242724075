import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from "react-lazy-load-image-component";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const GetStarted = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", mb: theme.spacing(3) }}
      >
        <Box
          component={LazyLoadImage}
          effect={"blur"}
          src={`${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/product/printer.png`}
          height={1}
          width={1}
          alt={"Eatabit printer"}
        />
      </Box>
      <Typography
        variant="h4"
        color="text.primary"
        align={"center"}
        gutterBottom
        sx={{
          fontWeight: 700,
        }}
      >
        Get started with eatabit today
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        sx={{ fontWeight: 400 }}
        align={"center"}
      >
        Join the thousands of restaurants, retail operations and integrators
        using Eatabit printers.
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "stretched", sm: "flex-start" }}
        justifyContent={"center"}
        marginTop={4}
      >
        <Button
          component={RouterLink}
          variant="contained"
          color="primary"
          size="large"
          fullWidth={isMd ? false : true}
          to={"/sign-in?intent=buy&source=eatabit"}
        >
          Buy
        </Button>
        <Box
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={{ sm: 2 }}
          width={{ xs: "100%", md: "auto" }}
        >
          <Button
            component={"a"}
            href={"https://documentation.eatabit.io"}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
            target={"_blank"}
          >
            Documentation
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GetStarted;
