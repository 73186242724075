import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SupportIcon from "@mui/icons-material/Support";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PrintIcon from "@mui/icons-material/Print";
import AlarmIcon from "@mui/icons-material/Alarm";
import CableIcon from "@mui/icons-material/Cable";
import ApiIcon from "@mui/icons-material/Api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LinkIcon from "@mui/icons-material/Link";

const jobStatIcons = {
  queued: <HourglassTopIcon color={"primary"} />,
  downloaded: <CloudDownloadIcon color={"primary"} />,
  prnted: <PrintIcon color={"green"} />,
  expired: <AlarmIcon color={"error"} />,
};

const Details = ({
  jobData: {
    attributes: {
      external_id,
      state,
      environment,
      api_version,
      copies,
      expire_seconds,
      late_print,
      status_url,
      status_url_method,
      client_printer_id
    },
  },
  printerData: { id: printerId },
}) => {
  return (
    <Fragment>
      <Box bgcolor={"alternate.main"}>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            padding: 2,
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <ListItem alignItems={"flex-start"}>
            <ListItemAvatar>
              <ReceiptIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Job ID"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Typography
                  sx={{ display: "inline" }}
                  component={"span"}
                  variant={"body2"}
                  color={"text.primary"}
                >
                  {external_id}
                </Typography>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>{jobStatIcons[state]}</ListItemAvatar>
            <ListItemText
              primary={"Status"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {state}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <PrintIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Printer"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <Link
                      component={RouterLink}
                      to={`/user/printers/${client_printer_id}`}
                      underline={"none"}
                    >
                      {printerId}
                    </Link>
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <CableIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Environment"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {environment}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <ApiIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"API version"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {api_version}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <ContentCopyIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Double print"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {(copies > 1).toString()}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <AlarmIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Expire seconds"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {expire_seconds}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <WatchLaterIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Late printed"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {late_print ? "true" : "false"}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <LinkIcon />
            </ListItemAvatar>
            <ListItemText
              primary={"Status url"}
              primaryTypographyProps={{ fontWeight: 800 }}
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {`${status_url_method.toUpperCase()} ${status_url}`}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
        </List>
      </Box>
      <Box marginTop={4} display={"flex"} justifyContent={"flex-start"}>
        <Box>
          <Typography paragraph>Need Support for this Job?</Typography>
          <Stack direction={"row"} spacing={2} marginTop={0.5}>
            <Button
              variant={"outlined"}
              startIcon={<SupportIcon />}
              component={RouterLink}
              to={`/user/support?printerId=${printerId}`}
            >
              Create ticket
            </Button>
          </Stack>
        </Box>
      </Box>
    </Fragment>
  );
};

Details.propTypes = {
  jobData: PropTypes.shape({}).isRequired,
  printerData: PropTypes.shape({}).isRequired,
};

export default Details;
