import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { company } from "constants/company";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;
const now = new Date();

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display={"flex"} alignItems={"center"} color={"primary.dark"}>
            <Box
              component={"img"}
              src={
                mode === "light"
                  ? `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_light.svg`
                  : `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_dark.svg`
              }
              height={1}
              width={1}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box marginLeft={2}>
              <Link
                variant={"body2"}
                underline={"none"}
                component={RouterLink}
                to={"/user/jobs"}
                color={"text.primary"}
              >
                Home
              </Link>
            </Box>
            <Box marginLeft={2}>
              <Link
                variant={"body2"}
                underline={"none"}
                component={"a"}
                href={"https://documentation.eatabit.io"}
                color={"text.primary"}
                target={"_blank"}
              >
                Documentation
              </Link>
            </Box>
            <Box marginLeft={2}>
              <Button
                variant={"contained"}
                color={"primary"}
                component={RouterLink}
                target={"blank"}
                to={"/sign-out"}
                size={"small"}
              >
                Sign out
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
        >
          &copy; {company.name}. {now.getFullYear()}, All rights reserved
        </Typography>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
