/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { urls, mimeType, httpCodes } from "constants/urls";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
});

const Form = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [alertVisible, setAlertVisable] = useState(false);

  const initialValues = {
    email: "",
  };

  const onSubmit = (values) => {
    fetch(urls.passwordResets, {
      method: "POST",
      headers: {
        Accept: mimeType,
        "Content-Type": mimeType,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (!response.ok) {
          formik.setErrors({
            email: "Email address not found",
          });
          throw response;
        } else {
          localStorage.removeItem("token");
          setAlertVisable(true);
        }
      })
      .catch((response) => {
        console.error(response);
        if (response.status === httpCodes.unauthorized) {
          navigate("/sign-out");
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const alert = () => (
    <Alert severity="success">
      <AlertTitle>Success</AlertTitle>
      An email was sent to you with a password reset link.
    </Alert>
  );

  return (
    <Box>
      {alertVisible ? alert() : null}
      <Box marginBottom={4} sx={{ mt: theme.spacing(2) }}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Recover account
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Forgot your password?
        </Typography>
        <Typography color="text.secondary">
          Enter your email address below and we'll get you back on track.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size={"large"}
                  variant={"outlined"}
                  component={RouterLink}
                  to={"/sign-in"}
                  fullWidth
                >
                  Back to Sign in
                </Button>
              </Box>
              <Button size={"large"} variant={"contained"} type={"submit"}>
                Send reset link
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
