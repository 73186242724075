import { authenticated } from "shared/authentication";

const authenticatedPages = {
  menu: [
    {
      title: "Home",
      href: "/user",
    },
    {
      title: "Support",
      href: "/support",
    },
    {
      title: "Sign out",
      href: "/sign-out",
    },
  ],
};
const unauthenticatedPages = {
  menu: [
    {
      title: "Sign up",
      href: "/sign-up",
    },
    {
      title: "Sign in",
      href: "/sign-in",
    },
    {
      title: "Support",
      href: "/support",
    },
  ],
};
const pages = () => authenticated() ? authenticatedPages : unauthenticatedPages;

export default pages;
