import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import ThemeModeToggler from "components/ThemeModeToggler";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const Topbar = ({ onSidebarOpen, colorInvert = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { mode } = theme.palette;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box display={"flex"} alignItems={"center"} color={"primary.dark"}>
        <Box
          component={"img"}
          src={
            mode === "light" && !colorInvert
              ? `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_light.svg`
              : `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_dark.svg`
          }
          height={1}
          width={1}
        />
      </Box>
      <Box display={"flex"}>
        <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
          <Box marginLeft={3}>
            <Link
              underline={"none"}
              component={RouterLink}
              to={"/user"}
              color={"text.primary"}
            >
              Home
            </Link>
          </Box>
          <Box marginLeft={3}>
            <Link
              underline="none"
              component="a"
              href={"https://documentation.eatabit.io"}
              color="text.primary"
              target={"_blank"}
            >
              Documentation
            </Link>
          </Box>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }} alignItems={"center"}>
          <Fragment>
            <Tooltip title="Menu">
              <IconButton onClick={handleClick} size="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 3,
                sx: {
                  overflow: "visible",
                  mt: 1.5,
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Link
                  underline={"none"}
                  component={"a"}
                  href={"https://documentation.eatabit.io"}
                  color={"text.primary"}
                  target={"_blank"}
                >
                  Documentation
                </Link>
              </MenuItem>
            </Menu>
          </Fragment>
        </Box>
        <Box marginLeft={{ xs: 2, md: 3 }} sx={{ my: "auto" }}>
          <IconButton
            color="primary"
            aria-label="shopping cart"
            onClick={() => {
              navigate("/user/cart");
            }}
          >
            <ShoppingCartIcon />
          </IconButton>
        </Box>
        <Box marginLeft={{ xs: 1, md: 4 }} sx={{ my: "auto" }}>
          <ThemeModeToggler />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }} marginLeft={2}>
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant={"outlined"}
            sx={{
              borderRadius: 2,
              minWidth: "auto",
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2),
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
