import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import SettingsIcon from "@mui/icons-material/Settings";
import ReceiptIcon from "@mui/icons-material/Receipt";

const Navigation = ({
  clientPrinterData: { id: clientPrinterId },
  printerData: { id: printerId },
}) => {
  const theme = useTheme();

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const links = [
    {
      title: "Configuration",
      path: `/user/printers/${clientPrinterId}`,
      icon: (
        <SettingsIcon
          sx={{ mr: theme.spacing(0.75), verticalAlign: "bottom" }}
        />
      ),
    },
    {
      title: "Jobs",
      path: `/user/jobs?terms=${printerId}`,
      icon: (
        <ReceiptIcon
          sx={{ mr: theme.spacing(0.75), verticalAlign: "bottom" }}
        />
      ),
    },
  ];

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 8 }}>
      {links.map((link, i) => (
        <Link
          key={i}
          to={link.path}
          component={RouterLink}
          variant={"body2"}
          underline={"none"}
          sx={{
            textTransform: "uppercase",
            color:
              activeLink === link.path
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            fontWeight: activeLink === link.path ? 600 : 400,
          }}
        >
          {link.icon}
          {link.title}
        </Link>
      ))}
    </Stack>
  );
};

Navigation.propTypes = {
  clientPrinterData: PropTypes.shape({}).isRequired,
  printerData: PropTypes.shape({}).isRequired,
};

export default Navigation;
