const { REACT_APP_API_SCHEME, REACT_APP_API_HOST } = process.env;
const baseUrl = `${REACT_APP_API_SCHEME}://${REACT_APP_API_HOST}/v1`;
const urls = {
  signIn: `${baseUrl}/login`,
  passwordResets: `${baseUrl}/password_resets`,
  user: `${baseUrl}/user`,
  events: `${baseUrl}/events`,
  clientPrinters: `${baseUrl}/client_printers`,
  adminClientPrinters: `${baseUrl}/admin/client_printers`,
  jobs: `${baseUrl}/jobs`,
  client: `${baseUrl}/client`,
  accounts: `${baseUrl}/accounts`,
  alert_profile: `${baseUrl}/alert_profile`,
  stripeSessions: `${baseUrl}/stripe/checkout_session`,
  tickets: `${baseUrl}/tickets`,
};
const mimeType = "application/vnd.api+json";
const httpCodes = {
  badRequest: 400,
  unauthorized: 401,
}

export { urls, mimeType, httpCodes };
