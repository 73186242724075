import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { Form, Information } from "./components";

const Printer = ({
  clientPrinterData,
  setClientPrinterData,
  printerData,
  setPrinterData,
  isMd,
}) => {
  const theme = useTheme();

  return (
    <Grid container spacing={{ xs: 2, md: 8 }} sx={{ maxWidth: 1200 }}>
      <Grid item xs={12} md={6}>
        <Form
          clientPrinterData={clientPrinterData}
          setClientPrinterData={setClientPrinterData}
          printerData={printerData}
          setPrinterData={setPrinterData}
          isMd={isMd}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Fragment>
          <Divider
            variant={"middle"}
            sx={{ display: isMd ? "none" : null, mb: theme.spacing(3) }}
          />
          <Information
            clientPrinterData={clientPrinterData}
            printerData={printerData}
          />
        </Fragment>
      </Grid>
    </Grid>
  );
};

Printer.propTypes = {
  clientPrinterData: PropTypes.shape({}).isRequired,
  setClientPrinterData: PropTypes.func.isRequired,
  printerData: PropTypes.shape({}).isRequired,
  setPrinterData: PropTypes.func.isRequired,
  isMd: PropTypes.bool.isRequired,
};

export default Printer;
